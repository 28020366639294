<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '数据增加' : '数据修改'" :visible.sync="isShow" width="670px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <!-- 参数栏 -->
        <br />
        <el-form size="medium" :inline="true" label-width="200px">
          <el-form-item label="微信公众号名称">
            <el-input v-model="m.officailName" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="微信公众号appid">
            <el-input v-model="m.officailAppid" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="微信公众号密钥">
            <el-input v-model="m.officailAppSecret" style="width: 300px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="回调域名(电商通不填)">
            <el-input v-model="m.uinBase64" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item v-if="m.type==2" label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        id: 0,
        officailName: "",
        officailAppid: "",
        officailAppSecret: "",
        uinBase64: "",
        status: 0,
        type: 1
      },
    };
  },
  methods: {
    // 打开
    open: function (data, type) {
      this.id = data.id;
      this.isShow = true;
      this.m.type = type
      if (this.id == 0 || data == 0) {
        // 如果是添加
        console.log("数据添加");
        this.id = 0;
      } else {
        // id 非 0 代表 是要修改 先查询一下，把旧值显示在input中
        console.log("数据修改");
        console.log(this.m);
        this.m.officailName = data.officailName;
        this.m.officailAppid = data.officailAppid;
        this.m.officailAppSecret = data.officailAppSecret;
        this.m.uinBase64 = data.uinBase64;
        this.m.status = data.status;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    claerRedisData() {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v2/admin/cleanProviderInfoAll",
        this.m,
        function () {
        },
        defaultCfg
      );
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        type: "put",
        isBody: true, //是否是请求体请求
      };
      this.m.id = null;
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          "/v1/officail/save",
          this.m,
          function () {
            self.sa.alert("增加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
              if (self.m.type == 2) {
                self.claerRedisData();
              }

            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        var defaultCfg2 = {
          isBody: true, //是否是请求体请求
        };
        this.m.id = this.id;
        this.sa.ajax(
          "/v1/officail/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert("修改成功", function () {
              if (self.m.type == 2) {
                self.claerRedisData();
              }
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
            });
          },
          defaultCfg2
        );
      }
    },
    claerData() {
      this.m.officailName = "";
      this.m.officailAppid = "";
      this.m.officailAppSecret = "";
      this.m.uinBase64 = "";
      this.m.status = 0;
    },
  },
  created: function () { },
};
</script>
